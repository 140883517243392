import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  Glyph,
  Icon,
  JustifyContent,
  JustifyItems,
  Layer,
  PlainButton,
  Text,
  TextAlign,
  Theme,
  TypePreset,
  TypeScale,
  useTheme,
  VisuallyHidden,
  XYGrid,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ReactElement } from "react";

export interface TopBannerProps {
  variant: TopBannerVariant;
  leftContent: ReactElement;
  rightContent: ReactElement | null;
  onTopBannerClose: () => void;
}

export enum TopBannerVariant {
  ACTION_PENDING = "action_pending",
  WARNING = "warning",
  ACTION_COMPLETED = "action_completed",
}

const getStyle = (
  theme: Theme,
  variant: TopBannerVariant
): { boxStyle: {}; typoStyle: {} } => {
  if (variant === TopBannerVariant.ACTION_PENDING) {
    const pendingStyle = {
      backgroundColor: theme.color(Color.Sunrise_50),
      borderBottom: "1px solid",
      borderColor: theme.color(Color.Sunrise_200),
    };
    return {
      boxStyle: pendingStyle,
      typoStyle: {},
    };
  }
  if (variant === TopBannerVariant.WARNING) {
    const pendingStyle = {
      backgroundColor: theme.color(Color.Sunset_400),
      borderBottom: "1px solid",
      borderColor: theme.color(Color.Sunset_600),
    };
    return {
      boxStyle: pendingStyle,
      typoStyle: {},
    };
  }
  return {
    boxStyle: {
      backgroundColor: theme.color(Color.Ultraviolet_700),
    },
    typoStyle: {
      color: Color.White,
    },
  };
};

const typographyStyle = {
  preset: TypePreset.Body_01,
  weight: FontWeight.SemiBold,
  size: [TypeScale.Size_02, null, null, TypeScale.Size_03],
};

const TopBanner: React.FC<TopBannerProps> = ({
  variant,
  leftContent,
  rightContent,
  onTopBannerClose,
}) => {
  const { theme } = useTheme();
  const { boxStyle, typoStyle } = getStyle(theme, variant);

  return (
    <Box
      minHeight="56px"
      minWidth="375px"
      layout="flex"
      flexDirection="row"
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      gutterV={0.75}
      gutterH={[0.5, null, null, 1]}
      css={{
        backgroundSize: "cover",
        borderWidth: "0px 0px 1px 0px",
        ...boxStyle,
      }}
    >
      {!rightContent ? (
        <Box css={{ textAlign: TextAlign.Center }}>
          <Text {...typographyStyle} {...typoStyle}>
            {leftContent}
          </Text>
        </Box>
      ) : (
        <XYGrid
          templateColumns={["1fr", null, "max-content 1fr"]}
          columnGap={[0.5, null, null, 0.75]}
          justifyItems={JustifyItems.Center}
          alignItems={AlignItems.Center}
        >
          <Box css={{ textAlign: TextAlign.Center }}>
            <Text {...typographyStyle} {...typoStyle}>
              {leftContent}
            </Text>
          </Box>
          <Box css={{ textAlign: TextAlign.Center }}>
            <Text {...typographyStyle} {...typoStyle}>
              {rightContent}
            </Text>
          </Box>
        </XYGrid>
      )}

      {variant === TopBannerVariant.ACTION_COMPLETED ? (
        <Layer mode="absolute" right={0}>
          <Box
            layout="flex"
            flexDirection="row"
            alignItems={AlignItems.Center}
            css={{ paddingRight: "20px" }}
          >
            <PlainButton
              onClick={onTopBannerClose}
              css={{ display: "inline-flex" }}
            >
              <VisuallyHidden>
                <Trans id="Close">Close</Trans>
              </VisuallyHidden>
              <Icon name={Glyph.Close} color={Color.White} />
            </PlainButton>
          </Box>
        </Layer>
      ) : null}
    </Box>
  );
};

export default TopBanner;
