import {
  PlainButton,
  Text,
  PlainLink,
  FontWeight,
} from "@gocardless/flux-react";
import { TrackingEvent } from "src/common/trackingEvents";
import { Route, getRouteURL, routerPush } from "src/common/routing";
import { Trans } from "@lingui/macro";

import ExtendDiscountDialog from "../incentive-banner/ExtendDiscountDialog";

import { TopBannerProps, TopBannerVariant } from "./TopBanner";
import IncentiviseCountdown from "./IncentiviseCountdown";
import { VerificationBannerEvents } from "./useVerificationBanner";

export enum VerificationBannerVariant {
  ADD_DETAILS = "add_details",
  FINISH_SETUP = "finish_setup",
  FINISH_SETUP_WITH_INCENTIVE = "finish_setup_with_incentive",
  EXTEND_INCENTIVE = "extend_incentive",
  CREATE_PAYMENTS_WITH_INCENTIVE = "create_payments_with_incentive",
  GC_SAS_REMEDIATION_PENDING = "gc_sas_remediation_pending",
  BILLING_OVERDUE = "billing_overdue",
  BLOCKED_TO_REONBOARD = "blocked_to_reonboard",
  ADA_PAYOUTS_DISABLED = "ada_payouts_disabled",
}

const blocked_to_reonboard_date = "20/05/2024";

export interface VerificationConfigProps {
  variant: VerificationBannerVariant;
  remainingDays: number | undefined;
  digits: string[] | undefined;
  openExtendDiscountDialog: boolean;
  toggleExtendDiscountDialog: React.Dispatch<React.SetStateAction<boolean>>;
  emitEvent: (name: VerificationBannerEvents) => void;
  sendSegmentEvent: (name: string, params?: {}) => void;
  forIframe?: boolean;
}

const onLinkClick = (forIframe: boolean, route: Route) => {
  forIframe
    ? (window.parent.location.href = getRouteURL({ route, absolute: true }))
    : routerPush({
        route,
      });
};

const onDialogClick = (
  forIframe: boolean,
  sendSegmentEvent: (name: string, params?: {}) => void,
  toggleExtendDiscountDialog: React.Dispatch<React.SetStateAction<boolean>>,
  emitEvent: (name: VerificationBannerEvents) => void
) => {
  sendSegmentEvent(
    TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_INCENTIVISE_DISCOUNT_EXTEND_CTA_CLICKED,
    {
      page: window.location.pathname,
    }
  );
  if (forIframe) {
    // postMessage used to communicate with enterprise dashboard
    emitEvent(VerificationBannerEvents.EXTEND_CLICKED);
  } else {
    toggleExtendDiscountDialog(true);
  }
};

export const VerificationConfig = ({
  variant,
  remainingDays,
  digits,
  openExtendDiscountDialog,
  toggleExtendDiscountDialog,
  emitEvent,
  sendSegmentEvent,
  forIframe = false,
}: VerificationConfigProps): Omit<
  TopBannerProps,
  "topBannerClosed" | "onTopBannerClose"
> => {
  const company_details_link = (
    <PlainButton
      textDecoration="underline"
      onClick={() => onLinkClick(forIframe, Route.Setup)}
    >
      <Trans id="verification-banner.blocked-to-reonboard.company-details">
        company details
      </Trans>
    </PlainButton>
  );

  const config: Record<
    VerificationBannerVariant,
    Omit<TopBannerProps, "topBannerClosed" | "onTopBannerClose">
  > = {
    [VerificationBannerVariant.ADD_DETAILS]: {
      variant: TopBannerVariant.ACTION_PENDING,
      leftContent: (
        <Trans id="verification-banner.add-details.description">
          We need some more details for account verification
        </Trans>
      ),
      rightContent: (
        <PlainButton
          textDecoration="underline"
          onClick={() => onLinkClick(forIframe, Route.Setup)}
        >
          <Trans id="verification-banner.add-details.cta">Add details</Trans>
        </PlainButton>
      ),
    },
    [VerificationBannerVariant.FINISH_SETUP]: {
      variant: TopBannerVariant.ACTION_PENDING,
      leftContent: (
        <Trans id="verification-banner.finish-setup.description">
          Verify your account to activate payments
        </Trans>
      ),
      rightContent: (
        <PlainButton
          textDecoration="underline"
          onClick={() => onLinkClick(forIframe, Route.Setup)}
        >
          <Trans id="verification-banner.finish-setup.cta">
            Finish your setup
          </Trans>
        </PlainButton>
      ),
    },
    [VerificationBannerVariant.FINISH_SETUP_WITH_INCENTIVE]: {
      variant: TopBannerVariant.ACTION_PENDING,
      leftContent: (
        <>
          {!!digits && !!remainingDays && (
            <IncentiviseCountdown
              digits={digits}
              remainingDays={remainingDays}
            />
          )}
        </>
      ),
      rightContent: (
        <PlainButton
          textDecoration="underline"
          onClick={() => onLinkClick(forIframe, Route.Setup)}
        >
          <Trans id="verification-banner.finish-setup.cta">
            Finish your setup
          </Trans>
        </PlainButton>
      ),
    },
    [VerificationBannerVariant.EXTEND_INCENTIVE]: {
      variant: TopBannerVariant.ACTION_COMPLETED,
      leftContent: (
        <>
          {!!digits && !!remainingDays && (
            <IncentiviseCountdown
              digits={digits}
              remainingDays={remainingDays}
            />
          )}
        </>
      ),
      rightContent: (
        <>
          <PlainButton
            textDecoration="underline"
            onClick={() =>
              onDialogClick(
                forIframe,
                sendSegmentEvent,
                toggleExtendDiscountDialog,
                emitEvent
              )
            }
          >
            <Trans id="verification-banner.extend-incentive.cta">Extend</Trans>
          </PlainButton>
          <ExtendDiscountDialog
            open={openExtendDiscountDialog}
            toggleDialog={() =>
              toggleExtendDiscountDialog(!openExtendDiscountDialog)
            }
          />
        </>
      ),
    },
    [VerificationBannerVariant.CREATE_PAYMENTS_WITH_INCENTIVE]: {
      variant: TopBannerVariant.ACTION_COMPLETED,
      leftContent: (
        <>
          {!!digits && !!remainingDays && (
            <IncentiviseCountdown
              digits={digits}
              remainingDays={remainingDays}
            />
          )}
        </>
      ),
      rightContent: (
        <PlainButton
          textDecoration="underline"
          onClick={() => onLinkClick(forIframe, Route.RequestPayments)}
        >
          <Trans id="verification-banner.create-payments.cta">
            Create payments
          </Trans>
        </PlainButton>
      ),
    },
    [VerificationBannerVariant.GC_SAS_REMEDIATION_PENDING]: {
      variant: TopBannerVariant.ACTION_PENDING,
      leftContent: (
        <Trans id="verification-banner.gc-sas-remediation.description">
          Action required: For regulatory reasons, we need some more details so
          you can continue using GoCardless
        </Trans>
      ),
      rightContent: (
        <PlainButton
          textDecoration="underline"
          onClick={() => onLinkClick(forIframe, Route.Setup)}
        >
          <Trans id="verification-banner.gc-sas-remediation.cta">
            Update your details
          </Trans>
        </PlainButton>
      ),
    },
    [VerificationBannerVariant.BILLING_OVERDUE]: {
      variant: TopBannerVariant.WARNING,
      leftContent: (
        <Trans id="verification-banner.billing-overdue.description">
          You owe money on your GoCardless account.
        </Trans>
      ),
      rightContent: (
        <PlainLink
          href="mailto:creditcontrol@gocardless.com"
          textDecoration="underline"
        >
          <Text weight={FontWeight.Bold}>
            <Trans id="verification-banner.billing-overdue.cta">
              Please contact us to settle your account.
            </Trans>
          </Text>
        </PlainLink>
      ),
    },
    [VerificationBannerVariant.BLOCKED_TO_REONBOARD]: {
      variant: TopBannerVariant.WARNING,
      leftContent: (
        <>
          <Trans id="verification-banner.blocked-to-reonboard.description">
            As of {blocked_to_reonboard_date}, your GoCardless account has been
            suspended due to inactivity. If you wish to reactivate as a
            GoCardless customer, please update your {company_details_link} and
            complete account verification. If you do not reactivate your
            GoCardless account, GoCardless will proceed with deleting your
            account in accordance with our data deletion practices.
          </Trans>
        </>
      ),
      rightContent: null,
    },
    [VerificationBannerVariant.ADA_PAYOUTS_DISABLED]: {
      variant: TopBannerVariant.ACTION_PENDING,
      leftContent: (
        <Trans id="verification-banner.ada-payouts-disable.left_content">
          Payouts have been temporarily disabled until you confirm your
          verification details.
        </Trans>
      ),
      rightContent: (
        <PlainButton
          textDecoration="underline"
          onClick={() => onLinkClick(forIframe, Route.Payouts)}
        >
          <Trans id="verification-banner.ada-payouts-disable.right_content">
            Find out more.
          </Trans>
        </PlainButton>
      ),
    },
  };

  return config[variant];
};
