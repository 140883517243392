import {
  CSSRulesFunction,
  Color,
  Visibility,
  Text,
  useTheme,
  Box,
  AlignItems,
} from "@gocardless/flux-react";
import { Plural } from "@lingui/macro";

interface IncentiviseCountdownProps {
  digits: string[];
  remainingDays: number;
}

const countdownStyle: CSSRulesFunction = () => ({
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: "18px",
});

const digitStyle: CSSRulesFunction = (theme) => ({
  display: "inline-block",
  padding: "2px 4px 0",
  marginRight: "1px",
  backgroundColor: theme.color(Color.Greystone_1400),
  color: theme.color(Color.Sunrise_50),
  height: "24px",
  ":first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  ":last-of-type": {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
});

const IncentiviseCountdown: React.FC<IncentiviseCountdownProps> = ({
  digits,
  remainingDays,
}) => {
  const { theme } = useTheme();

  return (
    <Box layout="flex" flexDirection="row" alignItems={AlignItems.Center}>
      <Visibility
        visible={["block", null, null, "none"]}
        css={{ paddingRight: "4px" }}
      >
        {remainingDays}
      </Visibility>
      <Visibility
        visible={["none", null, null, "block"]}
        css={{ paddingRight: "8px" }}
      >
        <Text css={countdownStyle(theme)}>
          <Text fontFamily="monospace">
            {digits.map((digit, index) => (
              <Text css={digitStyle(theme)} key={index}>
                {digit}
              </Text>
            ))}
          </Text>
        </Text>
      </Visibility>
      <Plural
        id="incentivise-countdown.days-left"
        value={remainingDays}
        one="day left with no fees"
        other="days left with no fees"
      />
    </Box>
  );
};

export default IncentiviseCountdown;
