import moment from "moment";
import { useCollectionsPermitted } from "src/components/routes/SetupPayments/common/hooks/useCollectionsPermitted";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { usePrimaryCreditor } from "src/queries/creditor";

export enum LowRiskThreshold {
  LEVEL_0 = "level0",
  LEVEL_1 = "level1",
  LEVEL_2 = "level2",
  LEVEL_3 = "level3",
}

const calculateDifferenceInDays = (dateString?: Date) => {
  if (!dateString) return 0;

  const givenDate = moment(dateString);
  const currentDate = moment();
  const differenceInDays = currentDate.diff(givenDate, "days");

  return differenceInDays;
};

export const useLowRiskThreshold = (): LowRiskThreshold | null => {
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_COLLECTIONS_PRE_VERIFICATION_TOGGLE,
  });
  const { collectionsEnabled } = useCollectionsPermitted();
  const creditor = usePrimaryCreditor();
  const creditorAgeInDays = calculateDifferenceInDays(creditor?.created_at);

  const level0 = collectionsEnabled;
  const level1 = collectionsEnabled && creditorAgeInDays >= 30;
  const level2 = collectionsEnabled && creditorAgeInDays >= 45;
  const level3 = collectionsEnabled && creditorAgeInDays >= 60;

  if (!isVariationOn) {
    return null;
  }
  if (level3) {
    return LowRiskThreshold.LEVEL_3;
  }
  if (level2) {
    return LowRiskThreshold.LEVEL_2;
  }
  if (level1) {
    return LowRiskThreshold.LEVEL_1;
  }
  if (level0) {
    return LowRiskThreshold.LEVEL_0;
  }
  return null;
};
