import { useRequiredVerificationList } from "@gocardless/api/dashboard/required-verification";
import { RequiredVerificationStatus } from "@gocardless/api/dashboard/types";

export interface GcSasRemediation {
  loaded: boolean;
  gcSasRemediationPending: boolean;
}

export function useGcSasRemediation(): GcSasRemediation {
  const { data: requiredVerifications } = useRequiredVerificationList();
  const gcSasRemediationVerification =
    requiredVerifications?.required_verifications?.find(
      (verification) =>
        verification.name?.valueOf() === "gc_sas_remediation" &&
        verification.status === RequiredVerificationStatus.Pending
    );
  return {
    loaded: !!requiredVerifications,
    gcSasRemediationPending: !!gcSasRemediationVerification,
  };
}
